import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from "axios";

// //请求地址
let baseUrl = '/api'
Vue.prototype.$baseUrl = 'http://aiotcomm.com.cn'
axios.defaults.baseURL = baseUrl

Vue.prototype.$http = (url, method, data, async, fun) => {
    axios({
        url: url,
        method: method,
        data: JSON.stringify(data),
        async: async
    }).then(resp => {

        fun(resp.data)
    }).catch(err => {
        console.log(err)
    })
}
Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')