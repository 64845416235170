import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'main',
        redirect: "/home",
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Main.vue'),
        children: [{
                path: '/home',
                name: 'home',

                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/Home.vue'),

            },
            {
                path: '/our',
                name: 'our',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/Our.vue'),

            },
            {
                path: '/about',
                name: 'about',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/About.vue'),

            },
            {
                path: '/project',
                name: 'project',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/Project.vue'),

            },
            {
                path: '/news',
                name: 'news',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/News.vue'),

            },
            {
                path: '/joinUs',
                name: 'joinUs',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/joinUs.vue'),

            },
            {
                path: '/personIntro',
                name: 'personIntro',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/personIntro.vue'),

            },
            {
                path: '/article',
                name: 'Article',
                component: () =>
                    import ('../views/Article.vue'),
            },
            {
                path: '/recruit',
                name: 'Recruit',
                component: () =>
                    import ('../views/Recruit .vue'),
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router